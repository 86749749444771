.monitor-footer {
  padding: 2rem 3rem;
  font-size: 14px;
  background-color: var(--cui-dark);
  color: var(--cui-light)!important;
  a {
    font-weight: bold;
    color: inherit;
    font-size: inherit!important;
    &:hover {
      color: #fff!important;
    }
  }

  &>h6 {
    margin-bottom: 2rem;
    color: var(--cui-link-color);
    font-weight: bold;
  }

  &-first {
    //display: grid;
    //grid-template-columns: max-content 1fr 20%;
    //margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      div {
        //&:first-child {
        //  padding-right: 11rem;
        //}
        //&:nth-child(1) {
        //  padding: 0 8rem 0 auto;
        //}
      }
    }


  }
  &-second {
    p {
      margin: 0;
    }
    margin-bottom: 0.5rem;
  }

  address {
    p {
      margin: 0;
      &:first-child {
        margin-bottom: 1rem;
      }
    }
  }

}
