.colour-chip {
  border-radius: 20%;
  border: 1px outset #ccc;
  box-shadow: inset rgba(0,0,0,0.3) 1px 1px 3px;
  margin: 0.5rem;
  width: 1rem;
  height: 1rem;
  display: block;
  &.center {
    margin: 0.5rem auto;
  }
}

.blur-overlay {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
  background: rgba(100,100,100,0.2);
  z-index: 200000;
  position: fixed;
}
