.territory-list {
  &__container {
    min-height: 6rem;
  }
  &__loader {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateY(-50%);
  }
}
