.map-page {

  &__header {
    display: flex;
    flex-direction: column;
    //position: absolute;
    //padding: 1rem 1rem 0 1rem;
    height: 0;
    position: static;
    z-index: 1032;
    gap: 1rem;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    &>* {
      pointer-events: initial;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      height: initial;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 0.5rem;
    }

  }

  &__body {
    margin: -1.55rem 0 0 0;
    height: calc(100vh - 65px);
    position: relative;
    z-index: 1030;
    background-color: var(--cui-body-bg);
    padding-bottom: var(--lower-tabs-height);

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
  }

  // This older container is only used on the map search page
  &-container {
    margin: -1.55rem -1.75rem 0 -1.75rem;
    height: calc(100vh - 65px);
    position: relative;
    z-index: 1030;
    background-color: var(--cui-body-bg);
    padding-bottom: var(--lower-tabs-height);

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
  }
  &__tabs,
  &-tabs {
    align-self: center;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    margin-bottom: auto;
    z-index: inherit;
    position: absolute;
    top: 0.5rem;
    &>*:last-child {
      margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
      align-self: initial;
      margin-left: auto;
      position: initial;
    }
  }

  &__visualiser {
    position: absolute;
    z-index: inherit;
    bottom: 0;

    @include media-breakpoint-up(lg) {
      position: static;
      width: 21rem;
    }
  }

  &-search-controls {
    background: var(--cui-body-bg);
    border: 1px solid var(--cui-gray);
    font-size: 1rem;
    z-index: inherit;
  }

  &-key {
    display: block;
    z-index: 501;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    border: 1px solid var(--cui-gray);
    background-color: var(--cui-body-bg);
    padding: 0.5rem;
    &>.markers {
      padding: 0.2rem 0;
      display: grid;
      grid-template-columns: auto auto;
      //align-content: center;
      &>.key-label {
        margin-left: 0.2rem;
        cursor: pointer;
      }
      &>* {
        margin: auto;
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }

    }
  }

  &-list-link {
    border: 1px solid var(--cui-gray);
    border-radius: 0;
    margin-right: 0.5rem;
    background: var(--cui-light);
    text-decoration: none;
    color: var(--cui-btn-color)!important;
    --cui-btn-active-color: var(--cui-btn-color);
    &.active {
      color: var(--cui-btn-active-color)!important;
      cursor: default!important;
    }
    &.active,
    &:active,
    &:focus,
    &:hover {
      background-color: var(--cui-white)!important;
      border-color: var(--cui-gray-300)!important;
    }
  }

  &-left-controls {
    //position: absolute;
    //top: 1rem;
    //left: 1rem;
    z-index: inherit;
    font-size: 1rem;
  }

  &-visualiser-controls {
    background: var(--cui-body-bg);
    border: 1px solid var(--cui-gray);
    width: 21rem;
    .content {
      padding: 0.5rem;
    }
  }

  &-group-controls {
    //position: absolute;
    //top: 1rem;
    //left: 1rem;
    //z-index: 501;
    background: var(--cui-body-bg);
    border: 1px solid var(--cui-gray);
  }

  &-vis-controls {
    position: absolute;
    top: 1rem;
    left: 1rem;
    border: 1px solid red;
  }
}

.monitor-map-icon {
  position: relative;
  z-index: 1;
  --pin-col: rgb(47, 119, 229);
  --list-col: rgb(47, 119, 229);
  &.static {
    height: 2rem;
    width: 2rem;
    display: block;
    &>span {
      position: initial;
      bottom: revert;
      left: revert;
      transform: none!important;
    }
  }

  &>span {
    z-index: 1 !important;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-0.6rem, 0);
    width: 2rem;
    height: 2rem;
    &:after {
      transition: all 100ms ease;
    }
    &>span {
      transition: all 100ms ease;

    }
  }

  &.stack {
    &>span {
      &:hover {
        &>span {
          transform: translateY(-5px);
        }
        &:after {
          transform: translate(5px, -2px);
        }
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: -0.2rem;
        width: 1.8rem;
        height: 0.8rem;
        background-color: #666;
        z-index: -1;
        border-radius: 50%;
        opacity: 0.6;
        box-shadow: 0 0 5px #666;
      }

      & > span {
        position: absolute;
        display: block;
        width: 2rem;
        height: 0.8rem;
        background-color: var(--list-col);
        left: 0;
        top: 30%;

        & > i {
          position: absolute;
          z-index: 3;
          display: block;
          left: 50%;
          top: 0;
          transform: translate(-50%,-1rem);
        }

        &:after {
          content: "";
          position: absolute;
          width: 0.9rem;
          height: 0.4rem;
          border-radius: 50%;
          display: block;
          top:0;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: var(--list-col);
          z-index: 2;
        }
        &:before {
          content: "";
          width: 2rem;
          height: 1rem;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 0;
          background-color: #eee;
          border: 2px solid var(--list-col);
          transform: translateY(-50%);
          box-shadow: 0 4px var(--list-col), 2px 7px 2px -2px #666, 0 6px #eee, 0 11px var(--list-col);
        }
      }
    }
  }

  &.single {
    & > span {
      bottom: 6px;
      &:hover {
        &>span {
          transform: rotate(-45deg) translate(5px, -5px);
        }
        &:after {
          transform: scale(0.7, 0.7) translate(6px, -2px);
        }
      }

      & > span {

        position: relative;
        display: block;
        width: 2rem;
        height: 2rem;
        bottom: 6px;
        left: 0;
        border-radius: 50% 50% 50% 0;
        transform: rotate(-45deg);
        z-index: 1 !important;
        background-color: #ddd;
        border: 2px solid var(--pin-col);

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 60%;
          height: 60%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background-color: var(--list-col);
          //border: 1px solid var(--pin-col);
          box-shadow: 0 2px 3px rgba(0,0,0,0.6) inset;
        }
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0.5rem;
        height: 0.5rem;
        bottom: -2px;
        left: 14px;
        border-radius: 50%;
        //transform: scale(1, 0.8) rotate(-20deg);
        z-index: 0;
        background-color: rgb(102, 102, 102);
        opacity: 0.8;
        box-shadow: 0 0 2px 2px rgb(102, 102, 102);
      }
    }
  }

  &-colour {

  }
}
