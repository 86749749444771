.territory {
  &__form {
    //position: absolute;
    //bottom: calc(1rem + var(--lower-tabs-height));
    //@include media-breakpoint-up(lg) {
    //  bottom: 1rem;
    //}
    //left: 1rem;
    background-color: var(--cui-white);
    //border: 1px solid var(--cui-gray-300);
    //padding: 1rem;
    //z-index: 5000;
    //border-radius: var(--cui-border-radius);
  }

  &__area-switch {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    padding: 1rem;
    border: 1px solid var(--cui-gray-300);
    z-index: 5000;
    background-color: var(--cui-white);

  }
}
