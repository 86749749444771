.visualiser {
  $r: &;
  &-controls {
    background: var(--cui-body-bg);
    border: 1px solid var(--cui-gray);
    min-width: 30rem;
  }
  &-control {
    padding: 0.5rem;
    white-space: nowrap;
    display: grid;
    grid-template-columns: 10rem auto;
    grid-gap: 0.5rem;
    align-items: baseline;
    width: 100%;
    &>*:last-child {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
