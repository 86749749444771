.card-tab {
  &__container {

  }
  &:first-of-type {
    margin-left: auto;
  }
  &:last-of-type {
    margin-right: -1.05rem;

  }

  margin: -1rem 0 -0.5rem 0;
  padding: 1rem 1rem 0 1rem;
  border: var(--cui-card-border-width) solid var(--cui-card-border-color);
  border-bottom: none;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  text-decoration: none;
  color: var(--cui-dark);
  background-color: var(--cui-light);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &:hover {
    text-decoration: none;
    color: var(--cui-dark);
    background-color: var(--cui-white);
  }
  &.active {
    background-color: var(--cui-white);
    color: var(--cui-dark);
    cursor: default!important;
    margin-bottom: -0.55rem;
    &:hover {
      //color: var(--cui-dark);
      //background-color: var(--cui-light);
    }
  }

}
