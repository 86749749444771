// Here you can add other styles
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;

:root {
  --segments-blue-300: #{$blue-300};
  --segments-blue-400: #{$blue-400};
  --segments-orange-300: #{$orange-300};
  --segments-orange-400: #{$orange-400};
  --segments-red-300: #{$red-300};
  --segments-red-400: #{$red-400};
  --segments-yellow-300: #{$yellow-300};
  --segments-yellow-400: #{$yellow-400};
  --dashboard-row-new: #{$success-25};
}



.search-tag {
  margin: 0 0.2rem;

  .tag-closer {

    font-size: 0.5rem;
    margin-left: 0.2rem;
    margin-right: -0.2rem;
    cursor: pointer;
  }
}

.pagination {
  .page-item {
    cursor: pointer;
    &.disabled {
      cursor: default;
    }
  }
}

.btn {
  //--cui-btn-color: #{$gray-100};
  //--cui-btn-active-color: #{$white};
  //--cui-btn-disabled-color: #{$gray-200};
  //--cui-btn-hover-color: #{$white};
  //--cui-btn-bg: #{$primary};

  &-primary {
    --cui-btn-color: #{$gray-100};
    --cui-btn-active-color: #{$white};
    --cui-btn-disabled-color: #{$gray-200};
    --cui-btn-hover-color: #{$white};
  }
}

.clinic-list {
  &-row {
    &.new {
      td {
        background-color: var(--dashboard-row-new)!important;
      }
    }
  }
}
