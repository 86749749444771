.standard-list {
  &__toggle {
    &.btn-status {
      --cui-btn-bg: var(--st-list-color);
      --cui-btn-color: #fff;
      --cui-btn-hover-color: var(--st-list-color);
      --cui-btn-hover-bg: #{$light-50};
      --cui-btn-hover-border-color: var(--st-list-color);
      //span {
      //  color: rgba(var(--st-list-color), 0.5);
      //
      //}

      &-none {
        span {
          filter: none!important;
        }
        --cui-btn-bg: #{$white}!important;
        --cui-btn-border-color: var(--cui-primary);
        --cui-btn-color: var(--cui-primary)!important;
        --cui-btn-hover-color: #{$dark-50};
        --cui-btn-hover-bg: #{$light-50};
        --cui-btn-hover-border-color: #{$dark-50};
      }
      //&-cold-lead {
      //  --cui-btn-bg: #{$blue-400};
      //  --cui-btn-hover-bg: #{$blue-100};
      //  --cui-btn-color: #{$gray-100};
      //  --cui-btn-hover-color: #{$gray-600};
      //}
      //&-qualified {
      //  --cui-btn-bg: #{$orange-500};
      //  --cui-btn-hover-bg: #{$orange-200};
      //  --cui-btn-color: #{$gray-100};
      //  --cui-btn-hover-color: #{$gray-600};
      //}
      //&-active {
      //  --cui-btn-bg: #{$yellow-400};
      //  --cui-btn-hover-bg: #{$yellow-200};
      //  --cui-btn-color: #{$white};
      //  --cui-btn-hover-color: #{$gray-600};
      //}
      //&-current {
      //  --cui-btn-bg: #{$green-500};
      //  --cui-btn-hover-bg: #{$green-200};
      //  --cui-btn-color: #{$gray-100};
      //  --cui-btn-hover-color: #{$gray-600};
      //}
      //&-dormant {
      //  --cui-btn-bg: #{$blue-100};
      //  --cui-btn-hover-bg: #{$gray-200};
      //  --cui-btn-color: #{$gray-500};
      //  --cui-btn-hover-color: #{$gray-500};
      //}
      //&-closed-rejected {
      //  --cui-btn-bg: #{$red-500};
      //  --cui-btn-hover-bg: #{$red-200};
      //  --cui-btn-color: #{$gray-100};
      //  --cui-btn-hover-color: #{$gray-600};
      //}
      //&-lost-client {
      //  --cui-btn-bg: #{$gray-700};
      //  --cui-btn-hover-bg: #{$gray-200};
      //  --cui-btn-color: #{$gray-100};
      //  --cui-btn-hover-color: #{$gray-600};
      //  //background-color: $light-50;
      //  //color: $light-50;
      //}
    }
  }
}
