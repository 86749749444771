.territory-agg {
  position: relative;

  @extend .map-data-panel;
  padding-bottom: 2rem;
  width: 26rem;
  pointer-events: all;
  height: 27rem;
  max-height: initial;
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
  &-header {
    @extend .map-data-panel-header;
  }
  &-body {
    @extend .map-data-panel-body;
  }
}

.territory-aggs-container {

}

.territory-aggs {
  &-container {
    position: absolute;
    z-index: 5001;
    right: 3.5rem;
    top: 6rem;
    display: flex;
    flex-direction: row-reverse;
    //display: grid;
    //grid-template-columns: auto auto;
    //grid-gap: 0.5rem;
    max-width: calc(100vw - 16rem - 2rem);
    max-height: calc(100vh - 6rem - 75px);
    padding-bottom: 10px;
    pointer-events: none;
    &>* {
      &:nth-child(2) {
        margin-right: 0.5rem;
      }
    }
  }
  &-list {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr auto;
    pointer-events: all;
    max-height: 100%;
    cursor: default;
    &.minimal {
      &>h5 {
        margin: 0.5rem;
      }
      border: 1px solid var(--cui-gray);
      background-color: var(--cui-body-bg);
      pointer-events: all;
    }
    &-body {
      overflow-y: auto;
      overflow-x: hidden;
      cursor: grab;
    }
    &-footer {
      position: relative;
      &:before {
        content: '';
        width: 100%;
        height: 0.8rem;
        position: absolute;
        top: -0.5rem;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0, rgba(255,255,255,0) 100%);
      }
      width: 100%;
      .pagination {
        margin: 0.5rem 0;
      }
    }
  }

}
