.clinic-item {
  flex-wrap: wrap;
  //&>.card {
  //  border: 1px solid red;
  //  @include media-breakpoint-up(sm) {
  //    border: 1px solid green;
  //    width: 50%;
  //    flex-grow: 1;
  //  }
  //}
}

.clinic {
  &-map-marker {
    cursor: default!important;
  }
}
