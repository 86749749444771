.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.body {

}

body:after {
  display: none;
  content: "xs";

  @include media-breakpoint-up(sm) {
    content: "sm"
  }
  @include media-breakpoint-up(md) {
    content: "md"
  }
  @include media-breakpoint-up(lg) {
    content: "lg"
  }
  @include media-breakpoint-up(xl) {
    content: "xl"
  }
  @include media-breakpoint-up(xxl) {
    content: "xxl"
  }
}
