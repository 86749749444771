.collapsing-tabs {
  background-color: var(--cui-white);
  .tabs {
    .tabs-toggler {
      width: 2rem;
      &:focus {
        border-color: transparent!important;
      }
    }
    &.collapsed {

      .tab-content {
        height: 0;
        max-height: 0;
        overflow: hidden;
      }
    }
    &:not(.collapsed) {
      .nav-link {
        &.active {
          margin-top: -5px;
        }
      }
    }
  }

  width: 100%;
  @include media-breakpoint-up(lg) {
    width: 28rem;

    .tabs {
      .tabs-toggler {
        display: none;
      }
      &.collapsed {
        .tab-content {
          height: auto;
          max-height: revert;
          overflow: revert;
        }
      }
      &:not(.collapsed) {
        .nav-link {
          &.active {
            margin-top: 0;
          }
        }
      }
    }
  }
}
