:root {
  --monitor-sidebar-width-default: 16rem;
  --monitor-sidebar-width-narrow: 4rem;
  --monitor-sidebar-width: var(--monitor-sidebar-width-default);
}

.monitor-sidebar {
  $sb: &;

  --monitor-sidebar-zindex: 1035;
  --monitor-sidebar-bg: #{$rare-base};
  --monitor-sidebar-padding-x: 0;
  --monitor-sidebar-padding-y: 0;
  --monitor-sidebar-color: #{$sidebar-nav-link-color};
  --monitor-sidebar-brand-color: rgba(255, 255, 255, 0.87);
  --monitor-sidebar-brand-bg: rgba(0, 0, 21, 0.2);
  --monitor-sidebar-background-active: #{$sidebar-nav-link-active-bg};


  width: var(--monitor-sidebar-width);
  background-color: var(--monitor-sidebar-bg);
  position: fixed;
  left: calc(var(--monitor-sidebar-width) * -1);
  height: 100vh;
  transition: left ease-in-out 0.2s;
  overflow: visible;
  z-index: var(--monitor-sidebar-zindex);
  color: var(--monitor-sidebar-color);

  @include media-breakpoint-down(sm) {
    &.active {
      left: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    transition: left ease-in-out 0.2s, width ease-in-out 0.1s;
    --monitor-sidebar-width: var(--monitor-sidebar-width-narrow);
    ~ * {
      --cui-sidebar-occupy-start: var(--monitor-sidebar-width-narrow);
    }
    left: 0;
  }

  @include media-breakpoint-between(sm, lg) {
    &.active {
      position: absolute;
      top: 0;
      left: 0;
      width: var(--monitor-sidebar-width-default);
      height: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    --monitor-sidebar-width: var(--monitor-sidebar-width-default);
    ~ * {
      --cui-sidebar-occupy-start: var(--monitor-sidebar-width-default);
    }

    &.collapsed {
      --monitor-sidebar-width: var(--monitor-sidebar-width-narrow);
      ~ * {
        --cui-sidebar-occupy-start: var(--monitor-sidebar-width-narrow);
      }


      &.active {
        position: absolute;
        top: 0;
        left: 0;
        width: var(--monitor-sidebar-width-default);
        height: 100%;

      }
    }
  }

  &__header {
    align-items: center;
    height: 3.5rem;
    &--title {
      display: block;
      flex-grow: 1;
      text-align: center;

      @include media-breakpoint-up(sm) {
        display: none;
        #{$sb}.active & {
          display: block;
        }
      }
      @include media-breakpoint-up(lg) {
        display: block;
        #{$sb}.collapsed:not(#{$sb}.active) & {
          display: none;
        }
      }
    }
  }

  &__footer {
    text-align: center;
  }

  &__toggle {
    padding: 0.5rem;
    line-height: 0;
    display: inline-block;
    transition: transform ease-in-out 0.2s;
    margin: 0 auto;
    background-color: var(--monitor-sidebar-bg);
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      transition: transform ease-in-out 0.2s, margin-right ease-in-out 0.2s;
      #{$sb}.active & {
        margin: 0 0.7rem;
      }
      #{$sb}:not(#{$sb}.active) & {
        transform: translateX(100%);
      }
    }

    @include media-breakpoint-up(sm) {
      margin: 0 0.7rem;
    }

    @include media-breakpoint-between(sm, lg) {

    }

    @include media-breakpoint-up(lg) {

      #{$sb}:not(#{$sb}.collapsed) & {
        display: none;
      }
    }

  }

  &__nav {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }

  &__nav-title {
    font-variant-caps: all-small-caps;
    padding-left: 0.8rem;
    @include media-breakpoint-up(sm) {
      padding-left: 0;
      #{$sb}.active & {
        padding-left: 0.8rem;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-left: 0.8rem;

      //#{$sb}:not(#{$sb}.collapsed) & {
      //  padding-left: 0.8rem;
      //}
      #{$sb}.collapsed:not(#{$sb}.active) & {
        padding-left: 0;
      }
    }
    //color: ;
  }

  &__nav-item {
    list-style: none;
    //white-space: nowrap;
    color: var(--monitor-sidebar-color);
    margin: 0 -0.5rem;

  }

  &__nav-link {
    --monitor-sidebar-icon-margin: 0.5rem;
    color: var(--monitor-sidebar-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.3rem;
    white-space: nowrap;

    &.active {
      background-color: var(--monitor-sidebar-background-active);
    }

    .nav-icon {
      width: 1.25rem;
      height: 1.25rem;
      fill: currentColor;
      text-align: center;
      display: flex;
      margin-right: var(--monitor-sidebar-icon-margin);
      overflow:initial;
      align-items: center;
    }

    @include media-breakpoint-up(sm) {
      #{$sb}:not(#{$sb}.active) & {
        .nav-icon {
          margin-right: 1000px;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      #{$sb}:not(#{$sb}.collapsed) & {
        .nav-icon {
          margin-right: var(--monitor-sidebar-icon-margin);
        }
      }
      #{$sb}.collapsed:not(#{$sb}.active) & {
        .nav-icon {
          margin-right: 1000px;
        }
      }
    }

  }
}
