.map {
  &-data-panel {
    align-self: center;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 5rem;
      right: 1rem;
      z-index: inherit;
    }

    width: 25rem;
    min-height: 10rem;
    max-height: calc(100% - 12rem);
    overflow: hidden;
    //border: 1px solid var(--cui-gray-500);
    //background-color: var(--cui-light);
    //border-radius: var(--cui-border-radius);
    [role="status"] {
      position: absolute;
      top: 50%;
      left: 50%;
    }
    &-header {
      //padding: 0.5rem 1.5rem;
      text-align: center;
      position: relative;
      //border-bottom: 1px solid var(--cui-gray-300);
      .btn-close {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &-body {
      //padding: 0.5rem;
      overflow: hidden auto;
      height: 100%;
      //.list-group-flush {
      //  margin: -0.5rem;
      //}

    }
  }
}
